.dropdown{
    margin-right: 24px;
    border-radius: 16px;
    border: solid 1px rgba(200, 200, 200, 0.6) ;
    height: 32px;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-item{
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    margin-right: 60px;
}


.scratch:hover{
    color: #1677ff;
}

.python:hover{
    color: #1677ff;
}

.cplus:hover{
    color: #1677ff;
}

.code-online:hover{
    color:#1677ff
}

.dropdown:hover{
    background-color: antiquewhite;
    cursor: pointer;
}