.code-container{
    margin-top: 36px;
    margin-left: 10vw;
    width: 80vw;
    border: solid 1px rgba(128, 128, 128, 0.3);
    border-radius: 8px;
    background-color: white;
    height: calc(100vh - 140px);
    margin-bottom: 40px;
}

.header{
    display: flex;
    margin-top: 24px;
    padding-bottom: 12px;
    height: 48px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 18px;
    border-bottom: solid 1px rgba(128, 128, 128, 0.3);
}

.header >div{
    margin-left: 24px;
}

.code-area{
    display: flex;
    height: calc(100vh - 264px);
    width: 80vw;
}

.editor{
    border: solid 1px rgba(128, 128, 128, 0.3);
    border-radius: 8px;
    margin: 24px;
}

.text-area{
    height: 100%;
    width: 40%;
    border: solid 1px rgba(128, 128, 128, 0.3);
    border-radius: 8px;
    margin: 24px 24px 24px 0;
    font-size: 14px;
    line-height: 24px;
    overflow: auto;
    resize: none;
    padding: 8px;
}