.intro-container{
    width: 100%;
    height: 100%;
    background-color: #fafadc;
}

.intro-title{
    height: 64px;
    align-items: center;
    color: black;
    font-size: 48px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: rgba(183, 255, 0, 0.5);
}

.intro-text-con{
    display: flex;
    justify-content: center;
}

.intro-text{
    letter-spacing: 4px;
    font-size: 18px;
    width: 60vw;
    background-color: white;
    margin: 12px 12px 24px 12px;
    padding: 24px 12px 24px 12px;
    border-radius: 8px;
    border: solid 1px rgba(127,127,127,0.3);
    box-shadow: 0 0 2px 2px rgba(127, 127, 127, 0.3);
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.intro-img-con{
    display: flex;
    width: 100%;
}

.intro-img-text{
    width: 40%;
    height: 400px;
    text-align: left;
}

.intro-img-text > div{
    height: 33%;
    font-size: 32px;
    margin-left: 100px;
    display: flex;
    align-items: center;
    color: #addcca;
}

.intro-images{
    width: 60%;
}

.intro-vedios-con{
    height: auto;
    width: 100%;
    display: flex;
    margin-top: 24px;
    padding-bottom: 24px;
}

.intro-vedios-con > div{
    width: 50%;
    display: flex;
    height: auto;
    justify-content: center;
}

.vedio{
    width: 600px;
    background-color: white;
    height: auto;
}