.csp-con{
    height: 100%;
    background-color: #fff;
}

.csp-header{
    background-color: #426791;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;
}

.csp-title{
    color: #fff;
    font-size: 80px;
    margin-bottom: 4px;
}

.csp-split-line{
    width: 300px;
    justify-self: center;
    border-bottom: solid 1px white;
    margin-bottom: 8px;
}

.csp-dis-text{
    font-size: 24px;
    color: #fff;
}

.options{
    background-color: #fff;
    display: flex;
    height: 92px;
    margin-top: -46px;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    box-shadow: 0 4px 7px rgba(4, 0, 0, .14);
    justify-content: space-evenly;
}

.l-option{
    height: 92px;
    line-height: 92px;
    font-size: x-large; 
}

.competition{
    height: auto;
    width: auto;
    margin-top: 24px;
}

.competition-title{
    line-height: 36px;
    font-size: 36px;
    text-align: left;
    color: #357dcf;
    font-weight: 600;
}

.competition-body{
    width: 80vw;
    border-radius: 8px;
    border: solid 1px rgba(4, 0, 0, 0.14);
    box-shadow: 0 4px 7px rgba(4, 0, 0, .14);
    margin: 24px 10vw;
    display: flex;
}

.competition-introduction{
    text-align: left;
    padding-left: 36px;
    margin-right: 12px;
    margin-top: auto;
    margin-bottom: auto;
}

.competition-introduction > div:first-child{
    font-size: xx-large;
    color: rgb(39, 71, 139);
    margin-bottom: 12px;
    font-weight: 600;
}

.competition-introduction>div:last-child{
    font-size: larger;
    font-weight: 500;
}

.competition-img{
    height: 300px;
    width: 300px;
}

.entry{
    background-color: rgb(212, 141, 10);
    font-size: x-large;
    height: auto;
    width: 200px;
    font-weight: 600;
}

.entry:hover{
    color: black !important;
    background-color: orange;
}

.prove{
    background-color: #f3f5f3;
    padding: 12px 0 36px 0;
}

.prove > div{
    font-size: xx-large;
    padding-top: 24px;
    font-weight: 600;
}

.study-introduction{
    margin-top: 36px;
    height: auto;
}


.oj-box{
    display: flex;
    justify-content: space-evenly;
    height: auto;
}

.oj-item{
    width: 40vw;
    margin: 24px;
}

.oj-item > div{
    border: solid 1px rgba(128, 128, 128, 0.3);
    height: 100px;
    line-height: 100px;
    border-radius: 8px;
    font-size: 24px;
    background-color: #f3f5f3;
    margin-bottom: 36px;
    font-weight: 500;
}

.oj-item > div:hover{
    box-shadow: 0 4px 7px rgba(4, 0, 0, .14);
    cursor: pointer;
}

