.head-con{
    height: 140px;
    justify-content:center;
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    align-items: center;
}

.avatar{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-small{
    height: 100%;
}

.img-con{
    height: 100px;
    width: 100px;
    display: block;
    align-self: center;
    justify-self: center;
}

.name{
    font-weight: 600;
    font-size: large;
    margin-bottom: 24px;
    margin-top: 8px;
}

.logo{
    height: 100%;
    width: auto;
    display: flex;
    font-size: 24px;
    align-items: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    flex:1;
}

.logo> img{
    height: 48px;
    width: 48px;
}

.right-info{
    display: flex;
    float: right;
}

.dropdown {
    margin-right: 24px;
    border-radius: 16px;
    border: solid 1px rgba(200, 200, 200, 0.6);
    height: 32px;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-self: center;
}

.content{
    margin: 0 36px 0 24px;
    border-radius: 8px;
    background-color: white;
}

.sider{
    height: calc(100vh - 82px);
}

.ant-menu-inline>.ant-menu-item {
    font-size: 18px !important;
    height: 48px !important;
}

.ant-menu-inline>.ant-menu-item>.ant-menu-item-icon{
    font-size: 20px !important;
}

.ant-menu-inline>.ant-menu-item>.ant-menu-title-content{
    margin-inline-start: 0 !important;
}

.ant-menu-inline>.ant-menu-item-only-child{
    font-size: 16px !important;
}

.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title{
    height: 48px !important;
    font-size: 18px !important;
}

.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-item-icon{
    font-size: 20px !important;
}

.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title>.ant-menu-item-content {
    margin-inline-start: 0 !important;
}