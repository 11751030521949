.py-container{
    height: auto;
    background-color: azure;
    padding-bottom: 100px;
}

.py-bnt{
    background-color: orange;
    font-size: x-large;
    height: auto;
    width: 300px;
}

.py-bnt:hover{
    color: black !important;
}

.hand-text{
    display: flex;
    justify-content: space-evenly;
    margin-left: 100px;
    margin-right: 100px;
}

.hand-text > div{
    background-color: #0e4c60;
    color: white;
    font-size: xx-large;
    width: 300px;
    height: 70px;
    font-weight: 600;
    line-height: 70px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.hand-text >div:hover{
    cursor: pointer;
}

.carouse{
    width: 90vw;
    margin-left: 5vw;
    height: 600px;
    border: solid 4px #6ae8e3;
    border-radius: 8px;
}

.carouse-content{
    height: 500px;
}