.grade-container{
    width: 100%;
    height: 100%;
    background-color: #fafadc;
}

.grade-title{
    height: 64px;
    align-items: center;
    color: black;
    font-size: 48px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: rgba(183, 255, 0, 0.5);
}

.grade-text-con{
    display: flex;
    justify-content: center;
}

.grade-text{
    letter-spacing: 4px;
    font-size: 18px;
    width: 60vw;
    background-color: white;
    margin: 12px 12px 24px 12px;
    padding: 24px 12px 24px 12px;
    border-radius: 8px;
    border: solid 1px rgba(127, 127, 127, 0.3);
    box-shadow: 0 0 2px 2px rgba(127, 127, 127, 0.3);
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.knowledge{
    display: flex;
    margin-left: 48px;
    margin-bottom: 24px;
}

.knowledge> div{
    height: 48px;
    font-size: 36px;
    margin-left: 12px;
    color:darkgray;
}

.pdfDownload{
    font-size: 18px;
    text-align: left;
    display: flex;
    margin-left: 100px;
}