
.container{
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #CBC0D3;
}

.form-container{
    border: solid 1px rgba(200, 200, 200, 0.6);
    border-radius: 8px;
    box-shadow: 0 0 2px 2px  rgba(230, 230, 230, 0.6);
    height: 500px;
    background-color:#EAC7CC;
    margin-right: 268px;
    z-index: 999;
}

.form-site{
    width: 288px;
    align-items: center;
}

.login-txt{
    font-size: xx-large;
    color: white;
    font-weight: 800;
    margin-top: 100px;
}

.site-form-item-icon{
    color: gray;
    opacity: 0.5;
}

.left-site{
    position: absolute;
    background-color: white;
    width: 300px;
    height: 400px;
    left: 50%;
    top: 50%;
    margin: -200px 0 0 -320px;
    border-radius: 8px 0 0 8px;
}

.right-site{
    position: absolute;
    background-color: white;
    width: 300px;
    height: 400px;
    left: 50%;
    top: 50%;
    margin: -200px 0 0 0;
    border-radius:0 8px 8px 0;
    box-shadow: 0 0 2px 2px rgba(173, 168, 168, 0.6);
}

.back-img{
    position: fixed;
    top: 36px;
    left: 36px;
    width: 48px;
    height: 48px;
}

.back-img:hover{
    cursor: pointer;
}