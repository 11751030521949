.uinfo-con{
    margin: 48px 0 0 48px;
}

.input-item{
    display: flex;
    font-size: large;
    color: rgba(128, 128, 128, 0.8);
    margin-top: 36px;
    height: 48px;
    align-items: center;
}

.input-item>input{
    width: 50%;
    height: 100%;
}

.input-item>button{
    height: 36px;
    margin-left: 24px;
    border-radius: 16px;
}

.save-btn{
    height: 48px;
    border-radius: 24px;
    font-size: large;
    margin: 24px 0 0 80px;
    width: 140px;
}
